import * as React from 'react'
import { type CSStage } from '../../types'
import { useSetCompanyCSStage } from '../../api/management'
import { useContext } from 'react'
import MessageContext from '../../contexts/MessageContext'
import CSStageSelector from '../csStageSelector/CSStageSelector'

interface CompanyCSStageAssignerProps {
  id: number
  value?: CSStage
  size?: 'medium' | 'small'
}

const CompanyCSStageAssigner: React.FC<CompanyCSStageAssignerProps> = ({ id, value, size = 'medium' }) => {
  const addMessage = useContext(MessageContext)
  const setCompanyCSStage = useSetCompanyCSStage()

  const setStage = (stage: CSStage): void => {
    setCompanyCSStage
      .mutateAsync({ company_id: id, stage })
      .then(() => {
        addMessage({
          id: Date.now(),
          message: 'Company stage updated',
          severity: 'success'
        })
      })
      .catch((err: any) => {
        console.error(err)
        addMessage({
          id: Date.now(),
          message: 'Unable to update company CS stage'
        })
      })
  }

  return <CSStageSelector value={value} onChange={(v) => setStage(v as CSStage)} size={size} />
}

export default CompanyCSStageAssigner

import * as React from 'react'
import { Box, FormControl, type InputBaseProps, InputLabel, MenuItem, type SelectChangeEvent } from '@mui/material'
import Select from '@mui/material/Select'
import { ManagementRole, type User } from '../../types'
import { useManagers } from '../../api/management'
import { useMemo } from 'react'

interface ManagerSelectorProps {
  value?: number | string | null
  role: ManagementRole
  label?: string
  onChange?: (role: number | undefined) => void
  size?: InputBaseProps['size']
  style?: React.CSSProperties
  emptyValueText?: string
  customOptions?: Array<Partial<User>>
}

const DefaultManagerLabels = {
  [ManagementRole.CS_MANAGER]: 'CS Manager'
}

const EMPTY_VALUE = '-'

const ManagerSelector: React.FC<ManagerSelectorProps> = ({ value, role, label, onChange = (v) => {}, size, style, emptyValueText, customOptions = [] }) => {
  const { data, isLoading, error } = useManagers(role)
  const finalLabel = label ?? DefaultManagerLabels[role as keyof typeof DefaultManagerLabels]
  const [currentManager, setCurrentManager] = React.useState(value?.toString() ?? EMPTY_VALUE)

  React.useEffect(() => {
    setCurrentManager(value?.toString() ?? EMPTY_VALUE)
  }, [value])

  const labelConfig: Record<string, string> = {}

  if (size !== 'small') {
    labelConfig.labelId = 'manager-select-label'
    labelConfig.label = finalLabel
  }

  const handleChange = (e: SelectChangeEvent): void => {
    if (e.target.value === EMPTY_VALUE) {
      onChange(undefined)
      setCurrentManager(EMPTY_VALUE)
    } else {
      onChange(Number(e.target.value))
      setCurrentManager(e.target.value)
    }
  }

  const isValueInvalid = useMemo(() => {
    if (!value || isLoading) {
      return false
    }

    return !data?.find((manager) => manager.id === value)
  }, [value, data])

  return (
    <Box style={style ?? { width: '100%' }} bgcolor="white">
      <FormControl fullWidth>
        {size !== 'small' && <InputLabel id="manager-select-label">{finalLabel}</InputLabel>}
        <Select {...labelConfig} id="manager-select" name="manager-select" value={currentManager} onChange={handleChange} variant="outlined" size={size ?? 'medium'} fullWidth={true} error={isValueInvalid}>
          <MenuItem value={EMPTY_VALUE}>{emptyValueText ?? 'No manager has been selected'}</MenuItem>
          {[...customOptions, ...(Array.isArray(data) ? data : [])].map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.email}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default ManagerSelector

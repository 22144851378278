import { type ReportConfig, type ReportConfigUpdate, type Report, type ReportInit } from '../types'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import instance from './client'

export const useReports = (blogId?: number): UseQueryResult<Report[], AxiosError> => {
  return useQuery<unknown, AxiosError, Report[]>({
    queryKey: ['report-list', blogId],
    queryFn: async () => await instance.get('/report/list', { params: { blogId } }).then((response) => response.data.data),
    enabled: !!blogId,
    refetchInterval: 10000
  })
}

export const useSendReport = (): UseMutationResult<Report, AxiosError, { id: number }> => {
  const queryClient = useQueryClient()
  return useMutation<Report, AxiosError, { id: number }>({
    mutationKey: ['send-report'],
    mutationFn: async ({ id }) => await instance.post('/report/send', { id }).then((response) => response.data.data),
    onSuccess: (data, input) => {
      queryClient.invalidateQueries(['report-list'])
    }
  })
}

export const useStartReportCreation = (): UseMutationResult<Report, AxiosError, { blogId: number; data: ReportInit }> => {
  const queryClient = useQueryClient()
  return useMutation<Report, AxiosError, { blogId: number; data: ReportInit }>({
    mutationKey: ['start-report-creation'],
    mutationFn: async ({ blogId, data: input }) =>
      await instance
        .post(
          '/report/monthly',
          {
            data: input
          },
          { params: { blogId } }
        )
        .then((response) => response.data.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['report-list'])
    }
  })
}

export const useRemoveReport = (): UseMutationResult<Report, AxiosError, { id: number }> => {
  const queryClient = useQueryClient()
  return useMutation<Report, AxiosError, { id: number }>({
    mutationKey: ['remove-report'],
    mutationFn: async ({ id }) => await instance.delete(`/report/${id}`).then((data) => data.data),
    onSuccess: (data, input) => {
      queryClient.invalidateQueries(['report-list'])
    }
  })
}

export const useReportConfig = (blogId?: number): UseQueryResult<ReportConfig | undefined, AxiosError> => {
  return useQuery<unknown, AxiosError, ReportConfig | undefined>({
    queryKey: ['report-config', blogId],
    queryFn: async () => await instance.get('/report/config', { params: { blogId } }).then((response) => response.data.data),
    enabled: !!blogId
  })
}

export const useUpdateReportConfig = (): UseMutationResult<ReportConfig, AxiosError, { blogId: number; data: ReportConfigUpdate }> => {
  const queryClient = useQueryClient()
  return useMutation<ReportConfig, AxiosError, { blogId: number; data: ReportConfigUpdate }>({
    mutationKey: ['update-report-config'],
    mutationFn: async ({ blogId, data: input }) =>
      await instance
        .post(
          '/report/config',
          {
            data: input
          },
          { params: { blogId } }
        )
        .then((response) => response.data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['report-config', data.blog_id], data)
    }
  })
}

import React, { useEffect, useMemo, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material'
import { AI_PROVIDER } from '../../types'
import type { FormikProps } from 'formik'
import { useObjectPath } from '../../hooks/useObjectPath'
import _ from 'lodash'

export const AI_PROVIDER_OPTIONS = {
  [AI_PROVIDER.OPENAI]: 'OpenAI',
  [AI_PROVIDER.ANTHROPIC]: 'Anthropic',
  [AI_PROVIDER.ANTHROPIC_AWS]: 'Anthropic (AWS)'
}

export const AI_MODELS_BY_PROVIDER = {
  [AI_PROVIDER.OPENAI]: {
    'gpt-4.5-preview': 'GPT-4.5 (preview)',
    'o3-mini': 'o3-mini',
    'o1-2024-12-17': 'o1',
    'gpt-4o-mini': 'GPT-4o Mini',
    'gpt-4o': 'GPT-4o',
    'gpt-4-turbo': 'GPT-4 Turbo',
    'gpt-4': 'GPT-4',
    'gpt-3.5-turbo': 'GPT-3.5 Turbo'
  },
  [AI_PROVIDER.ANTHROPIC]: {
    'claude-3-7-sonnet-latest': 'Claude 3.7 Sonnet',
    'claude-3-5-sonnet-20240620': 'Claude 3.5 Sonnet',
    'claude-3-opus-20240229': 'Claude 3 Opus',
    'claude-3-sonnet-20240229': 'Claude 3 Sonnet',
    'claude-3-haiku-20240307': 'Claude 3 Haiku'
  },
  [AI_PROVIDER.ANTHROPIC_AWS]: {
    'us.anthropic.claude-3-7-sonnet-20250219-v1:0': 'Claude 3.7 Sonnet'
  }
} as const

export type AIModelSelectorValue = {
  ai_provider: AI_PROVIDER
  ai_model: string
}

type AIModelSelectorProps = {
  formik: FormikProps<AIModelSelectorValue & any>
  formikContainer?: string // path to the fields container
  defaults?: Partial<AIModelSelectorValue>
  saved?: Partial<AIModelSelectorValue>
}

const fields = ['ai_provider', 'ai_model'] as Array<keyof AIModelSelectorValue>

export const AIModelSelector: React.FC<AIModelSelectorProps> = ({ formik, formikContainer = '', defaults = {}, saved = {} }) => {
  // getting container's values or fallback
  const { getFullPath } = useObjectPath(formikContainer)

  const fieldPaths = useMemo(() => {
    return fields.reduce<Record<keyof AIModelSelectorValue, string>>((acc, key) => {
      acc[key] = getFullPath(key)
      return acc
    }, {} as any)
  }, [getFullPath])

  // prefetching values for frequent usage
  const value = fields.reduce<AIModelSelectorValue>((acc, field) => {
    acc[field] = _.get(formik.values, getFullPath(field))
    return acc
  }, {} as any)

  const isCustomModel = useMemo(() => {
    return !Object.keys(AI_MODELS_BY_PROVIDER[value.ai_provider] || {}).includes(value.ai_model)
  }, [value.ai_provider, value.ai_model])

  const [aiModelType, setAiModelType] = useState(isCustomModel ? 'custom' : value.ai_model)

  const handleChangeProvider = (provider: AI_PROVIDER): void => {
    const firstModel = Object.keys(AI_MODELS_BY_PROVIDER[provider] || {})[0] || ''

    // set the first model of the selected provider
    formik.setFieldValue(fieldPaths.ai_provider, provider)
    formik.setFieldValue(fieldPaths.ai_model, firstModel)

    setAiModelType(firstModel || 'custom')
  }

  const handleChangeModel = (model: string): void => {
    setAiModelType(model)

    formik.setFieldValue(fieldPaths.ai_model, model === 'custom' ? '' : model)
  }

  // handling form reset properly
  useEffect(() => {
    if (value.ai_model !== aiModelType && aiModelType !== 'custom') {
      setAiModelType(isCustomModel ? 'custom' : value.ai_model)
    }
  }, [value.ai_model])

  return (
    <>
      <FormControl fullWidth style={{ marginBottom: 2 }}>
        <InputLabel>AI Provider</InputLabel>
        <Select name={fieldPaths.ai_provider} value={value.ai_provider} disabled={formik.isSubmitting} onBlur={formik.handleBlur} label={'AI Provider'} onChange={(e) => handleChangeProvider(e.target.value as AI_PROVIDER)}>
          {Object.entries(AI_PROVIDER_OPTIONS).map(([providerValue, providerLabel]) => (
            <MenuItem key={providerValue} value={providerValue}>
              {providerLabel}
              {saved.ai_provider && providerValue === saved.ai_provider ? ' (saved)' : ''}
              {defaults.ai_provider && providerValue === defaults.ai_provider ? ' (default)' : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 2 }} error={!!_.get(formik.touched, fieldPaths.ai_model) && !!_.get(formik.errors, fieldPaths.ai_model)}>
        <InputLabel>AI Model</InputLabel>
        <Select value={aiModelType} label="AI Model" onChange={(e) => handleChangeModel(e.target.value)} disabled={formik.isSubmitting}>
          {(AI_MODELS_BY_PROVIDER[value.ai_provider] && Object.entries(AI_MODELS_BY_PROVIDER[value.ai_provider]))?.map(([modelKey, modelLabel]) => (
            <MenuItem key={modelKey} value={modelKey}>
              {modelLabel}
              {saved.ai_model && modelKey === saved.ai_model ? ' (saved)' : ''}
              {defaults.ai_model && modelKey === defaults.ai_model ? ' (default)' : ''}
            </MenuItem>
          ))}
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {aiModelType === 'custom' && (
        <TextField
          fullWidth
          name={fieldPaths.ai_model}
          label="Custom AI Model"
          value={value.ai_model}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!_.get(formik.touched, fieldPaths.ai_model) && !!_.get(formik.errors, fieldPaths.ai_model)}
          helperText={_.get(formik.touched, fieldPaths.ai_model) && (_.get(formik.errors, fieldPaths.ai_model) as string)}
        />
      )}
    </>
  )
}

import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { type BlogGSCReports, ROUTES } from '../../types'
import type { ColumnDef } from '@tanstack/react-table'
import css from './GSC.module.css'

const monthColumns = _.range(12)
  .map((i) => moment().subtract(i, 'months').format('YYYY/MM'))
  .reverse()
  .map((month) => ({
    accessorKey: `inspection.${month as any}`,
    header: moment(month, 'YYYY/MM').format('MMM/YY'),
    cell: (cell: any) => {
      const activeData = cell.getValue()
      if (!activeData) {
        return '-'
      }

      const { clicks, impressions, seoaiClicks, homeClicks, publishedContent } = JSON.parse(JSON.stringify(activeData))
      return (
        <>
          clicks: {clicks || '-'}
          <br />
          impr.: {impressions || '-'}
          <br />
          SEOAI clicks: {seoaiClicks || '-'}
          <br />
          Home clicks: {homeClicks || '-'}
          <br />
          Content: {publishedContent || '-'}
        </>
      )
    }
  }))

const columns: Array<ColumnDef<BlogGSCReports>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (cell) => (
      <Link to={ROUTES.BLOGS + '/' + cell.row.original.id} target={'blank'} rel={'noopener noreferrer'} className={css.userLink}>
        {cell.getValue() as string}
      </Link>
    )
  },
  ...monthColumns
]

export default columns

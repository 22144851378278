import React, { useContext, useEffect } from 'react'
import { useCompanyAllowedDomains, useAddAllowedDomain, useRemoveAllowedDomain } from '../../api/company'
import MessageContext from '../../contexts/MessageContext'
import { type CompanyAllowedDomain } from '../../types'
import Empty from '../Empty/Empty'
import { Box, Button } from '@mui/material'
import * as Yup from 'yup'
import Spinner from '../../components/spinner/Spinner'
import AddBoxIcon from '@mui/icons-material/AddBox'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import css from './Company.module.css'

interface CompanyAllowedDomainsProps {
  list: CompanyAllowedDomain[]
  companyId: number
}

const AllowedDomains: React.FC<CompanyAllowedDomain & { onDelete: (domainId: number) => void }> = ({ id, domain, company_id: companyId, onDelete }) => {
  return (
    <div className={css.allowedDomainWrapper}>
      <Button className={css.deleteBtn} onClick={() => onDelete(id)}>
        <HighlightOffIcon />
      </Button>
      <Box>{domain}</Box>
    </div>
  )
}

const CompanyAllowedDomainsFC: React.FC<CompanyAllowedDomainsProps> = ({ list, companyId }) => {
  const addAllowedDomain = useAddAllowedDomain()
  const removeAllowedDomain = useRemoveAllowedDomain()
  const addMessage = useContext(MessageContext)

  const [newDomains, setNewDomains] = React.useState<string[]>([])
  const [currentList, setList] = React.useState<CompanyAllowedDomain[]>(list)

  const saveNewDomains = (): void => {
    if (newDomains.length === 0) return
    let brokenLink: string = ''
    const testPassed = newDomains.every((domain, idx) => {
      const check = Yup.string().url().isValidSync(domain)

      if (!check) {
        brokenLink = domain
      }
      return check
    })
    if (!testPassed) {
      alert(`Some new links doesn't pass check. (${brokenLink}) Please check them and try again. Links must be valid URLs. (e.g. https://example.com/)`)
      return
    }
    addAllowedDomain
      .mutateAsync({ companyId, domains: newDomains })
      .then((data) => {
        setList([])
        setList(data.allowedHosts)
        setNewDomains([])
        addMessage({ id: Date.now(), message: 'Domains added successfully', severity: 'success' })
      })
      .catch((error) => {
        addMessage({ id: Date.now(), message: error.response?.data?.message ?? 'Error adding domains', severity: 'error' })
      })
  }

  useEffect(() => {
    const onKeyDown = (e: any): void => {
      if (e.key === 'Enter') {
        saveNewDomains()
      }
    }
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [newDomains])

  const onRemoveAllowedDomain = (domainId: number): void => {
    removeAllowedDomain
      .mutateAsync({ companyId, domainId })
      .then((data) => {
        setList(data.allowedHosts)
        addMessage({ id: Date.now(), message: 'Domain removed successfully', severity: 'success' })
      })
      .catch((error) => {
        addMessage({ id: Date.now(), message: error.response?.data?.message ?? 'Error deleting domains', severity: 'error' })
      })
  }

  const addNewDomain = (): void => {
    setNewDomains([...newDomains, ''])
  }

  const editNewDomain = (idx: number): ((e: any) => void) => {
    const newList = structuredClone(newDomains)
    return (e: any): void => {
      newList[idx] = e.target.value.trim()
      setNewDomains(newList)
    }
  }

  const removeNewDomain = (index: number): void => {
    const listToSave = newDomains.filter((_, i) => i !== index)
    setNewDomains(listToSave)
  }

  return (
    <div className={css.allowedContainerWrapper}>
      <div>{currentList?.length > 0 ? 'Allowed domains:' : 'No domains set. Add one if needed.'}</div>
      <div>{currentList?.length > 0 && currentList.map((domain) => <AllowedDomains key={domain.id} {...domain} onDelete={onRemoveAllowedDomain} />)}</div>
      <Button onClick={() => addNewDomain()}>
        <AddBoxIcon /> Add new
      </Button>
      <div>
        {newDomains.map((domain, index) => (
          <div className={css.newDomainWrapper} key={index}>
            <div className={css.allowedDomainWrapper}>
              <Button className={css.deleteBtn} onClick={() => removeNewDomain(index)}>
                <HighlightOffIcon />
              </Button>
              <Box>
                <input className={css.newDomainInput} autoFocus placeholder="Enter new domain" type="text" value={domain} onChange={editNewDomain(index)} />
              </Box>
            </div>
          </div>
        ))}
      </div>
      {newDomains.length > 0 && (
        <Button onClick={() => saveNewDomains()}>
          <SaveAltIcon /> Save
        </Button>
      )}
    </div>
  )
}

const Container: React.FC<{ companyId: number }> = ({ companyId }) => {
  const { data, isLoading, error } = useCompanyAllowedDomains(companyId)
  const message = (error?.response?.data ?? error?.message ?? 'Wrong Company Id') as string
  if (isLoading) return <Spinner marginY={8} />
  if (data) return <CompanyAllowedDomainsFC list={data.allowedHosts || []} companyId={companyId} />
  return <Empty message={message} />
}
export default Container

import React, { useEffect, useState } from 'react'
import css from './GSC.module.css'
import { useCompaniesGSCHistory } from '../../api/gsc'
import { Table, TableBody, TableRow, TableCell, TableHead, Pagination, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, Switch, FormControlLabel } from '@mui/material'
import { type BlogGSCReports, ROUTES, type CompanyContentHistory, ManagementRole } from '../../types'
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table'
import columns from './Columns'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import ManagerSelector from '../../components/managerSelector/ManagerSelector'
import Spinner from '../../components/spinner/Spinner'

const LIMIT = 100

interface TableProps {
  data: BlogGSCReports[]
}
const TableContent: React.FC<TableProps> = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <Table className={css.table}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Content: React.FC = (props) => {
  const [limit, setLimit] = useState<number>(LIMIT)
  const [csManager, setCsManager] = useState<number | undefined>(undefined)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { data: { data, total } = { data: [] as BlogGSCReports[], total: 0 }, isLoading } = useCompaniesGSCHistory({
    limit,
    offset: (page - 1) * limit,
    name: search,
    cs_manager: csManager
  })

  return (
    <div className={css.container}>
      <PageHeaderText>Google Search Console Analytics</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput}>
          <TextField
            id="outlined-basic"
            label={'Name to search'}
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setPage(1)
            }}
          />
          <ManagerSelector role={ManagementRole.CS_MANAGER} style={{ marginLeft: '15px', width: '200px' }} emptyValueText="-" onChange={(v) => setCsManager(v)} value={csManager} customOptions={[{ id: 0, email: 'Without manager' }]} />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setSearch('')
              setPage(1)
              setCsManager(undefined)
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <div className={css.cpp_selector}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Companies per page</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                value={limit}
                label="Companies per page"
                onChange={(e) => {
                  setLimit(Number(e.target.value))
                  setPage(1)
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {isLoading ? <Spinner showHint hint="Loading... This may take up to 30 seconds. Please wait" /> : <TableContent data={data} />}
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
    </div>
  )
}

export default Content

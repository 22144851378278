import { useCallback } from 'react'

// helper to construct full paths
export function useObjectPath(containerPath?: string): any {
  // getting full path for the object
  const getFullPath = useCallback(
    (path: string): string => {
      return containerPath ? `${containerPath}.${path}` : path
    },
    [containerPath]
  )

  return { getFullPath }
}

import * as React from 'react'
import { useContext } from 'react'
import { ManagementRole } from '../../types'
import MessageContext from '../../contexts/MessageContext'
import ManagerSelector from '../managerSelector/ManagerSelector'
import { useSetCompanyManager } from '../../api/management'

interface CompanyManagerAssignerProps {
  id: number
  role: ManagementRole
  value: number | undefined | null
  size?: 'medium' | 'small'
}

const CompanyManagerAssigner: React.FC<CompanyManagerAssignerProps> = ({ id, role, value, size = 'medium' }) => {
  const addMessage = useContext(MessageContext)
  const setCompanyManager = useSetCompanyManager()

  const setManager = (userId: number | undefined): void => {
    setCompanyManager
      .mutateAsync({ company_id: id, role, user_id: userId })
      .then(() => {
        addMessage({
          id: Date.now(),
          message: 'Company manager updated',
          severity: 'success'
        })
      })
      .catch((err: any) => {
        console.error(err)
        addMessage({
          id: Date.now(),
          message: 'Unable to set company manager'
        })
      })
  }

  return <ManagerSelector role={ManagementRole.CS_MANAGER} value={value} onChange={setManager} size={size} />
}

export default CompanyManagerAssigner

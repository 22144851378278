import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import BlogStats from './BlogStats'
import BlogInfo from './BlogInfo'
import Audits from './Audits'
import LookerStudio from './BlogLookerStudio'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import ContentAlerts from './ContentAlerts/ContentAlerts'
import { useBlog } from '../../api/blog'
import Spinner from '../../components/spinner/Spinner'
import Empty from '../Empty/Empty'
import Reports from './Reports/Reports'

const tabs = [
  { label: 'Info', value: 'info' },
  { label: 'Stats', value: 'stats' },
  { label: 'Reporting', value: 'reporting' },
  { label: 'Audits', value: 'audits' },
  { label: 'Content Alerts', value: 'contentAlerts' },
  { label: 'Automatic Reports', value: 'reports' }
]

const Blog: React.FC = () => {
  const params = useParams()
  const blogId = Number(params.id)
  const [tab, setTab] = React.useState(tabs[0].value)

  let errorMessage = ''
  const blogRequest = useBlog(blogId)

  if (blogRequest.isLoading) {
    return <Spinner marginY={8} />
  }

  if (blogRequest.isError) {
    const error = blogRequest.error
    errorMessage = (error as any)?.message ?? 'Wrong Blog Id'
  }

  if (blogRequest.data) {
    return (
      <div>
        <PageHeaderText>Blog #{blogId}</PageHeaderText>
        <Box display="flex" padding={4}>
          <Stack flex={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="basic tabs example">
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Box>
            {tab === 'info' && (
              <Box marginTop={2}>
                <BlogInfo blogId={blogId} />
              </Box>
            )}
            {tab === 'stats' && (
              <Box marginTop={2}>
                <BlogStats blogId={blogId} />
              </Box>
            )}
            {tab === 'reporting' && (
              <Box marginTop={2}>
                <LookerStudio blogId={blogId} />
              </Box>
            )}
            {tab === 'audits' && (
              <Box marginTop={2}>
                <Audits blog={blogRequest.data} />
              </Box>
            )}
            {tab === 'contentAlerts' && (
              <Box marginTop={2}>
                <ContentAlerts blogId={blogId} />
              </Box>
            )}
            {tab === 'reports' && (
              <Box marginTop={2}>
                <Reports blogId={blogId} />
              </Box>
            )}
          </Stack>
        </Box>
      </div>
    )
  }

  return <Empty message={errorMessage} />
}

export default Blog

import React from 'react'
import { type FormikProps } from 'formik'
import { type CompanyConfig, type CompanyConfigExtended } from '../../types'
import { Box, FormControl, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AIModelSelector } from '../aiModelSelector/AIModelSelector'
import { RequestGroupsConfig } from '../requestGroupsConfig/RequestGroupsConfig'

interface CompanyConfigFormProps {
  formik: FormikProps<CompanyConfig>
  config: CompanyConfigExtended
}

const CompanyConfigForm: React.FC<CompanyConfigFormProps> = ({ formik, config }) => {
  return (
    <>
      <Box>
        <h4>General</h4>
        <Stack spacing={2} width={'100%'}>
          <AIModelSelector
            formik={formik}
            defaults={{
              ai_provider: config.ai_provider_default,
              ai_model: config.ai_model_default
            }}
            saved={{
              ai_provider: config.ai_provider_custom ? config.ai_provider_custom : undefined,
              ai_model: config.ai_model_custom ? config.ai_model_custom : undefined
            }}
          />
          <FormControl fullWidth error={formik.touched.ai_temperature && !!formik.errors.ai_temperature}>
            <TextField
              fullWidth
              id="ai_temperature"
              name="ai_temperature"
              label="AI Temperature"
              type="number"
              value={formik.values.ai_temperature}
              placeholder={config.ai_temperature_default.toString() + ' (default)'}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.ai_temperature && !!formik.errors.ai_temperature}
              helperText={formik.touched.ai_temperature && formik.errors.ai_temperature}
              inputProps={{
                step: 0.01,
                min: 0,
                max: 1
              }}
            />
          </FormControl>
        </Stack>
      </Box>
      <Box>
        <h4>Request Group Configs</h4>
        <RequestGroupsConfig
          formik={formik}
          formikPath="request_config"
          defaults={{
            ai_provider: config.ai_provider,
            ai_model: config.ai_model,
            ai_temperature: config.ai_temperature
          }}
        />
      </Box>
    </>
  )
}

export default CompanyConfigForm

import React, { useState } from 'react'
import { Box, Button, TextField, FormControl, InputLabel, MenuItem, Select, IconButton, Stack, Alert, Tooltip } from '@mui/material'
import { useRequestGroups } from '../../api/request'
import { AI_PROVIDER, type AIConfigFields, type CompanyConfig } from '../../types'
import { AI_MODELS_BY_PROVIDER, AIModelSelector } from '../aiModelSelector/AIModelSelector'
import Spinner from '../spinner/Spinner'
import type { FormikProps } from 'formik'
import { HelpOutline, Delete } from '@mui/icons-material'
import _ from 'lodash'

interface RequestGroupsConfigProps {
  formik: FormikProps<CompanyConfig>
  formikPath: string
  defaults?: Partial<AIConfigFields>
}

export const RequestGroupsConfig: React.FC<RequestGroupsConfigProps> = ({ formik, formikPath = '', defaults = {} }) => {
  const { data: groupsData, isLoading, error } = useRequestGroups()
  const [selectedGroup, setSelectedGroup] = useState('')

  if (isLoading) return <Spinner marginY={0} size={24} />
  if (error) {
    return (
      <Alert variant="outlined" severity="error">
        Error loading request groups
      </Alert>
    )
  }

  const configs = (_.get(formik.values, formikPath as keyof CompanyConfig) ?? {}) as Record<string, AIConfigFields>

  const setConfigs = (value: Record<string, AIConfigFields>): void => {
    formik.setFieldValue(formikPath, value)
  }

  const handleAddGroup = (): void => {
    if (selectedGroup && !configs[selectedGroup]) {
      const newGroupProvider = defaults.ai_provider ?? AI_PROVIDER.OPENAI

      setConfigs({
        ...configs,
        [selectedGroup]: {
          ai_provider: newGroupProvider,
          ai_model: defaults.ai_model ?? Object.keys(AI_MODELS_BY_PROVIDER[newGroupProvider])[0],
          ai_temperature: defaults.ai_temperature ?? 0.7
        }
      })
      setSelectedGroup('')
    }
  }

  const handleRemoveGroup = (group: string): void => {
    const { [group]: _, ...rest } = configs
    setConfigs(rest)
  }

  return (
    <Box>
      <Box display="flex" width="50%" style={{ marginBottom: 16 }}>
        <FormControl style={{ width: '50%', marginRight: 8 }}>
          <InputLabel>Request Group</InputLabel>
          <Select value={selectedGroup} label="Request Group" onChange={(e) => setSelectedGroup(e.target.value)}>
            {Object.keys(groupsData ?? {}).map((name) => (
              <MenuItem key={name} value={name} disabled={!!configs[name]}>
                {groupsData?.[name]?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button disabled={!selectedGroup} variant="outlined" onClick={handleAddGroup}>
          Add group config
        </Button>
      </Box>

      <Stack spacing={2} width={'100%'}>
        {Object.keys(groupsData || {}).map((group) => {
          const config = configs[group]
          if (!config) {
            return null
          }

          const groupPath = `${formikPath}.${group}`
          const temperaturePath = `${groupPath}.ai_temperature`

          return (
            <Box key={group} border={'1px solid #ccc'} borderRadius={1} padding={2} marginBottom={2}>
              <Box display="flex" marginBottom={2}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" width={'100%'}>
                  <strong style={{ fontSize: 14, fontWeight: 600, textTransform: 'uppercase' }}>{groupsData?.[group]?.label ?? group}</strong>
                  <Tooltip
                    title={
                      <div>
                        {(groupsData?.[group]?.options || []).map((option) => (
                          <div key={option}>{option}</div>
                        ))}
                      </div>
                    }
                  >
                    <IconButton size="small" style={{ marginLeft: 8, opacity: 0.5 }}>
                      <HelpOutline fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <IconButton onClick={() => handleRemoveGroup(group)} size="small">
                  <Delete fontSize="inherit" />
                </IconButton>
              </Box>
              <Stack spacing={2} width={'100%'}>
                <AIModelSelector formik={formik} formikContainer={groupPath} />
                <TextField
                  fullWidth
                  type="number"
                  name={`${groupPath}.ai_temperature`}
                  label="AI Temperature"
                  value={config.ai_temperature}
                  placeholder={defaults?.ai_temperature ? `${defaults?.ai_temperature} (default)` : '0.7'}
                  disabled={formik.isSubmitting}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!_.get(formik.touched, temperaturePath) && !!_.get(formik.errors, temperaturePath)}
                  helperText={_.get(formik.touched, temperaturePath) && _.get(formik.errors, temperaturePath)}
                  inputProps={{ step: 0.01, min: 0, max: 1 }}
                />
              </Stack>
            </Box>
          )
        })}
      </Stack>
    </Box>
  )
}

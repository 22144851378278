import React, { useContext, useState } from 'react'
import MessageContext from '../../../../contexts/MessageContext'
import { ActivityStatus, type ContentAlertConfigUpdate } from '../../../../types'
import { Box, Button, CircularProgress, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import { useContentAlertConfig, useUpdateContentAlertConfig } from '../../../../api/contentAlert'
import { locale } from '../../../../constants'
import * as Yup from 'yup'
import Spinner from '../../../../components/spinner/Spinner'
import ValuesInput from '../../../../components/valuesInput/ValuesInput'

const editableContentAlertConfigSchema = Yup.object({
  status: Yup.string().oneOf(Object.values(ActivityStatus)).required('Required'),
  clicks: Yup.number().integer().min(1).required('Required'),
  timeframe: Yup.number().integer().min(1).required('Required'),
  delay: Yup.number().integer().min(1).required('Required'),
  emails: Yup.array().of(Yup.string().email().required()).min(1, 'At least one email is required').required('Required')
})

interface ContentAlertConfigInfoProps {
  config: ContentAlertConfigUpdate
}

const ContentAlertConfigInfo: React.FC<ContentAlertConfigInfoProps> = ({ config }) => {
  const updateConfig = useUpdateContentAlertConfig()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<ContentAlertConfigUpdate> = useFormik<ContentAlertConfigUpdate>({
    initialValues: {
      ...config,
      emails: [...config.emails]
    },
    validationSchema: editableContentAlertConfigSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      updateConfig
        .mutateAsync({ blogId: config.blog_id, data: values })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .catch((err: any) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.error || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <Box display="flex">
      <Stack flex={4}>
        <form onSubmit={formik.handleSubmit}>
          <Stack marginTop={4} spacing={3}>
            <TextField
              select
              fullWidth
              id="status"
              name="status"
              label="Status"
              value={formik.values.status}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.status && !!formik.errors.status}
              helperText={formik.touched.status && formik.errors.status}
            >
              <MenuItem value="enabled">Enabled</MenuItem>
              <MenuItem value="disabled">Disabled</MenuItem>
            </TextField>
            <TextField
              fullWidth
              type="number"
              id="clicks"
              name="clicks"
              label="Clicks Threshold"
              value={formik.values.clicks}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.clicks && !!formik.errors.clicks}
              helperText={formik.touched.clicks && formik.errors.clicks}
              inputProps={{ min: 1 }}
            />
            <TextField
              fullWidth
              type="number"
              id="timeframe"
              name="timeframe"
              label="Timeframe (days)"
              value={formik.values.timeframe}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.timeframe && !!formik.errors.timeframe}
              helperText={formik.touched.timeframe && formik.errors.timeframe}
              inputProps={{ min: 1 }}
            />
            <TextField
              fullWidth
              type="number"
              id="delay"
              name="delay"
              label="Alert Delay (days)"
              value={formik.values.delay}
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!formik.touched.delay && !!formik.errors.delay}
              helperText={formik.touched.delay && formik.errors.delay}
              inputProps={{ min: 1 }}
            />
            <ValuesInput
              values={formik.values.emails}
              disabled={formik.isSubmitting}
              onChange={(newValues) => {
                formik.setFieldValue('emails', newValues)
              }}
              label="Emails"
              placeholder="Add values one by one and press Enter"
              error={!!formik.touched.emails && !!formik.errors.emails}
              helperText={formik.touched.emails && formik.errors.emails ? formik.errors.emails.toString() : undefined}
            />
          </Stack>
          <Stack marginTop={4} alignItems="flex-end">
            <Button disabled={formik.isSubmitting} type="submit" variant="contained" color="primary" sx={{ width: '230px' }}>
              {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Save
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useContentAlertConfig(blogId)
  const [showCreateForm, setShowCreateForm] = useState(false)

  // default config
  const defaultConfig: ContentAlertConfigUpdate = {
    blog_id: blogId,
    status: ActivityStatus.ENABLED,
    clicks: 5,
    timeframe: 7,
    delay: 90,
    emails: []
  }

  const getContent = (): React.ReactElement => {
    if (isLoading) return <Spinner marginY={8} />

    // if config exists, showing the config form
    if (data) {
      return <ContentAlertConfigInfo config={data} />
    }

    // if user clicked "Create Config", show form with the default config
    if (showCreateForm) {
      return <ContentAlertConfigInfo config={defaultConfig} />
    }

    // no config created yet
    const message = ((error?.response?.data as any)?.error ?? error?.message ?? 'No config found') as string
    return (
      <Stack justifyContent="center" alignItems="center" marginY={4}>
        <Typography color="#999">{message}</Typography>
        <Button variant="contained" style={{ width: 230 }} onClick={() => setShowCreateForm(true)} sx={{ marginTop: 2 }}>
          Create a config
        </Button>
      </Stack>
    )
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Config</Typography>
      </Box>
      {getContent()}
    </div>
  )
}

export default Container

import React, { useState } from 'react'
import css from './Blogs.module.css'
import { useBlogs } from '../../api/blog'
import { Box, Button, FormControl, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { type AuditLighthouseItem, type Blog, ROUTES } from '../../types'
import columns from './Columns'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import { useLastLighthouseAuditsList } from '../../api/audit'
import Spinner from '../../components/spinner/Spinner'

const LIMIT = 10

interface TableProps {
  data: Array<Blog & { audit: AuditLighthouseItem | undefined | null }>
}

const TableBlogs: React.FC<TableProps> = ({ data }) => {
  const navigate = useNavigate()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      edit: (id: number) => {
        navigate(`${ROUTES.BLOGS}/${id}`)
      }
    }
  })

  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Blogs: React.FC = () => {
  const [limit, setLimit] = useState<number>(LIMIT)
  const [page, setPage] = useState(1)

  const [blogName, setBlogName] = useState<string>('')
  const [blogUrl, setBlogUrl] = useState<string>('')
  const [pluginVersion, setPluginVersion] = useState<string>('')

  const {
    data: { data, total } = { data: [] as Blog[], total: 0 },
    isLoading,
    error
  } = useBlogs({
    limit,
    offset: (page - 1) * limit,
    name: blogName,
    url: blogUrl,
    plugin_version: pluginVersion
  })

  const blogIds = data ? data.map((blog) => blog.id) : []
  const lighthouseAuditsRequest = useLastLighthouseAuditsList(blogIds)

  const combinedData = data.map((blog) => ({
    ...blog,
    audit: lighthouseAuditsRequest.data ? lighthouseAuditsRequest.data.find((audit) => audit.blog_id === blog.id) : null
  }))

  return (
    <div className={css.container}>
      <PageHeaderText>Blogs</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput}>
          <TextField id="blog-name-input" label={'Name to search'} variant="outlined" value={blogName} onChange={(e) => setBlogName(e.target.value)} />
          <TextField style={{ marginLeft: '15px' }} id="blog-url-input" label={'URL to search'} variant="outlined" value={blogUrl} onChange={(e) => setBlogUrl(e.target.value)} />
          <TextField style={{ marginLeft: '15px' }} id="plugin-version-input" label={'Plugin version to search'} variant="outlined" value={pluginVersion} onChange={(e) => setPluginVersion(e.target.value)} />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setBlogName('')
              setBlogUrl('')
              setPluginVersion('')
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Blogs per page</InputLabel>
            <Select labelId="select-label" id="simple-select" value={limit} label="Blogs per page" onChange={(e) => setLimit(Number(e.target.value))}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableBlogs data={combinedData} />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination count={Math.ceil(total / limit)} page={page} onChange={(e, v) => setPage(v)} />
      </Box>
    </div>
  )
}

export default Blogs

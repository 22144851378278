import instance from './client'
import { type PaginatedData, type ResponseData, type SystemActionExtended, type SystemActionType } from '../types'
import { type AxiosError } from 'axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const useSystemActions = (params: QueryParamsSystemAction): UseQueryResult<ResponseData<SystemActionExtended>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<SystemActionExtended>>({
    queryKey: ['system-actions', params.limit, params.offset, params?.action_id, params?.action_type, params?.user_id, params?.user_email],
    queryFn: async () => await instance.get('/system-actions', { params }).then((data) => data.data)
  })
}

interface QueryParamsSystemAction extends PaginatedData {
  action_id?: number
  action_type?: SystemActionType
  user_id?: number
  user_email?: string
}

import * as React from 'react'
import { Box, CircularProgress } from '@mui/material'

interface SpinnerProps {
  marginY?: number
  size?: number
  hint?: string
  showHint?: boolean
}

const Spinner: React.FC<SpinnerProps> = ({ marginY = 4, size = 32, hint, showHint }) => {
  return (
    <Box marginY={marginY} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress size={size} />
      {showHint && <p style={{ color: '#1976d2' }}>{hint ?? 'Loading...'}</p>}
    </Box>
  )
}

export default Spinner

export type TemplateFieldGroup = {
  name: string
  test: (fieldName: string) => boolean
  color?: string
  isDefault?: boolean
}

export const defaultFieldGroup: TemplateFieldGroup = {
  name: 'Ungrouped',
  test: (v: any) => true,
  isDefault: true
}

export const fieldGroupsByTab: Record<string, TemplateFieldGroup[]> = {
  general: [
    {
      name: 'Description Expansion',
      test: (fieldName: string) => fieldName.includes('_description_expanded_')
    }
  ],
  frame: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('frame_base')
    },
    {
      name: 'Utils',
      test: (fieldName: string) => ['frame_description_template', 'frame_description_from_content_template', 'frame_keywords_template', 'frame_prompt_template'].includes(fieldName)
    },
    {
      name: 'Formatting',
      test: (fieldName: string) => fieldName.includes('_format_')
    }
  ],
  ideas: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('ideas_base')
    },
    {
      name: 'Utils',
      test: (fieldName: string) => ['ideas_custom_prompt_template', 'ideas_keywords_template', 'ideas_location_template', 'ideas_search_terms_template', 'ideas_service_template'].includes(fieldName)
    }
  ],
  content: [
    {
      name: 'Tone',
      test: (fieldName: string) => fieldName.startsWith('content_change_tone')
    },
    {
      name: 'Stylizing',
      test: (fieldName: string) => fieldName.startsWith('content_stylize')
    },
    {
      name: 'Personalization',
      test: (fieldName: string) => fieldName.startsWith('content_personalize')
    }
  ],
  page: [
    {
      name: 'Base',
      test: (fieldName: string) => ['page_purpose_template', 'page_description_template', 'page_intents_template', 'page_audience_template'].includes(fieldName)
    },
    {
      name: 'Meta',
      test: (fieldName: string) => ['page_description_misalignments_template', 'page_meta_suggestion_template', 'page_use_meta_suggestion_template'].includes(fieldName)
    },
    {
      name: 'Content',
      test: (fieldName: string) => ['page_gaps_template', 'page_gaps_suggestions_template', 'page_final_suggestions_template', 'page_meta_suggestion_template', 'page_use_content_suggestion_template'].includes(fieldName)
    }
  ],
  post: [
    {
      name: 'Base',
      test: (fieldName: string) => fieldName.startsWith('post_base')
    }
  ],
  audit: [
    {
      name: 'Suggestion',
      test: (fieldName: string) => fieldName.startsWith('audit_suggestion_')
    }
  ]
}

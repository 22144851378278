import React, { useState } from 'react'
import Empty from '../Empty/Empty'
import { useTemplatesByProvider } from '../../api/template'
import ProviderTemplates from './components/ProviderTemplates/ProviderTemplates'
import { AI_PROVIDER } from '../../types'
import { useDebounce } from 'use-debounce'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { Clear } from '@mui/icons-material'
import useTemplate from '../../hooks/useTemplate'
import Spinner from '../../components/spinner/Spinner'
import Loader from '../../components/loader/Loader'

const Container: React.FC = () => {
  const [aiProvider, setAiProvider] = useState(AI_PROVIDER.OPENAI)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const { data: templates, isLoading, error } = useTemplatesByProvider(aiProvider)
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200)
  const templatesParsed = useTemplate({ data: templates, filter: debouncedSearchTerm, provider: aiProvider })
  const areControlsDisabled = isSubmitting || isLoading

  return (
    <div style={{ width: '100%' }}>
      <PageHeaderText>Templates</PageHeaderText>
      {isSubmitting ? <Loader /> : null}
      <Box display="flex" paddingInline={2} paddingBlock={4}>
        <Stack flex={4}>
          <Box display="flex">
            <FormControl sx={{ width: '200px' }}>
              <InputLabel id="select-label">Provider</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                value={aiProvider}
                label="Provider"
                size="small"
                onChange={(e) => {
                  setAiProvider(e.target.value as AI_PROVIDER)
                }}
                disabled={areControlsDisabled}
              >
                {Object.entries(AI_PROVIDER).map(([providerLabel, providerValue]) => (
                  <MenuItem key={providerValue} value={providerValue}>
                    {providerLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              disabled={areControlsDisabled}
              style={{ width: '40%', marginLeft: '15px', alignSelf: 'flex-end' }}
              size="small"
              id="search"
              name="search"
              label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: searchTerm && (
                  <IconButton aria-label="clear search" onClick={() => setSearchTerm('')} disableRipple={true} edge="end">
                    <Clear />
                  </IconButton>
                )
              }}
            />
          </Box>
          {isLoading ? <Spinner marginY={8} /> : templates?.length ? <ProviderTemplates key={aiProvider} templateParsed={templatesParsed} onSubmitChange={(v) => setIsSubmitting(v)} /> : <Empty message={error?.response?.data.message} />}
        </Stack>
      </Box>
    </div>
  )
}

export default Container

import instance from './client'
import { type Company, type CompanyContentHistory, type CompanyConfig, type CSStage, type QueryParams, type ResponseData, type CompanyConfigExtended, type CompanyAllowedDomains, type AddAllowedDomain, type RemoveAllowedDomain } from '../types'
import { type AxiosError } from 'axios'
import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'

export const useCompanies = (params: QueryParams<Company> & { cs_manager?: number; cs_stage?: CSStage }): UseQueryResult<ResponseData<Company>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<Company>>({
    queryKey: ['companies', params.offset, params.search, params.limit, params.cs_manager, params.cs_stage],
    queryFn: async () => await instance.get('/companies', { params }).then((data) => data.data)
  })
}

export const useCompaniesExpired = (): UseQueryResult<Array<Pick<Company, 'id' | 'name' | 'email'> & { days: number }>, AxiosError> => {
  return useQuery<unknown, AxiosError, Array<Pick<Company, 'id' | 'name' | 'email'> & { days: number }>>({
    queryKey: ['expired'],
    refetchInterval: 1000 * 24 * 3600, // 1d
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    queryFn: async () => await instance.get('/companies', { params: { expired: true } }).then((data) => data.data)
  })
}

export const useCompany = (id?: number): UseQueryResult<Company, AxiosError> => {
  return useQuery<unknown, AxiosError, Company>({
    queryKey: ['company', id],
    queryFn: async () => await instance.get('/companies/' + id).then((data) => data.data),
    enabled: !!id
  })
}

interface RemoveInput {
  id: number
}
export const useRemoveCompany = (): UseMutationResult<void, AxiosError, RemoveInput> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, RemoveInput>({
    mutationKey: ['remove-company'],
    mutationFn: async ({ id }) => await instance.delete(`/companies/${id}`).then((data) => data.data),
    onSuccess: (data, input) => {
      queryClient.invalidateQueries(['companies'])
      queryClient.invalidateQueries(['users'])
      queryClient.invalidateQueries(['user'])
      queryClient.removeQueries(['company', input.id])
    }
  })
}

interface UpdateInput extends Partial<Company> {
  reset: boolean
}

export const useUpdateCompany = (): UseMutationResult<Company, AxiosError, UpdateInput> => {
  const queryClient = useQueryClient()
  return useMutation<Company, AxiosError, UpdateInput>({
    mutationKey: ['update-company'],
    mutationFn: async ({ id, ...input }) => await instance.patch(`/companies/${id}`, input).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['companies'])
      queryClient.setQueryData(['company', data.id], data)
    }
  })
}

export const useCompanyConfig = (companyId?: number): UseQueryResult<CompanyConfigExtended, AxiosError> => {
  return useQuery<unknown, AxiosError, CompanyConfigExtended>({
    queryKey: ['company-config', companyId],
    queryFn: async () => await instance.get(`/companies/${companyId}/config`).then((data) => data.data),
    enabled: !!companyId,
    cacheTime: 0
  })
}

export const useUpdateCompanyConfig = (): UseMutationResult<CompanyConfig, AxiosError, CompanyConfig> => {
  const queryClient = useQueryClient()
  return useMutation<CompanyConfig, AxiosError, CompanyConfig>({
    mutationKey: ['update-company-config'],
    mutationFn: async ({ company_id: companyId, ...input }) => await instance.post(`/companies/${companyId}/config`, input).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['company-config', data.company_id], data)
    }
  })
}

interface RemoveCompanyConfigInput {
  company_id: number
}
export const useRemoveCompanyConfig = (): UseMutationResult<void, AxiosError, RemoveCompanyConfigInput> => {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError, RemoveCompanyConfigInput>({
    mutationKey: ['remove-company-config'],
    mutationFn: async ({ company_id: companyId }) => await instance.delete(`/companies/${companyId}/config`).then((data) => data.data),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['companies'])
      queryClient.removeQueries(['company', data.company_id])
      queryClient.invalidateQueries(['company-config', data.company_id], data)
    }
  })
}

export const useCompaniesContentHistory = (params: QueryParams<CompanyContentHistory> & { cs_manager?: number }): UseQueryResult<ResponseData<CompanyContentHistory>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<CompanyContentHistory>>({
    queryKey: ['companies-content-history', params.offset, params.search, params.limit, params.cs_manager],
    queryFn: async () => await instance.get('/companies/content-history', { params }).then((data) => data.data)
  })
}

export const useCompanyAllowedDomains = (companyId?: number): UseQueryResult<CompanyAllowedDomains, AxiosError> => {
  return useQuery<unknown, AxiosError, CompanyAllowedDomains>({
    queryKey: ['company-allowed-domains', companyId],
    queryFn: async () => await instance.get(`/companies/${companyId}/allowedHosts`).then((data) => data.data),
    enabled: !!companyId,
    cacheTime: 0
  })
}

export const useAddAllowedDomain = (): UseMutationResult<CompanyAllowedDomains, AxiosError, AddAllowedDomain> => {
  const queryClient = useQueryClient()
  return useMutation<CompanyAllowedDomains, AxiosError, AddAllowedDomain>({
    mutationKey: ['add-new-allowed-domain'],
    mutationFn: async ({ companyId, ...input }) => await instance.post(`/companies/${companyId}/allowedHosts`, input).then((data) => data.data)
  })
}

export const useRemoveAllowedDomain = (): UseMutationResult<CompanyAllowedDomains, AxiosError, RemoveAllowedDomain> => {
  const queryClient = useQueryClient()
  return useMutation<CompanyAllowedDomains, AxiosError, RemoveAllowedDomain>({
    mutationKey: ['remove-allowed-domain'],
    mutationFn: async ({ companyId, domainId }) => await instance.delete(`/companies/${companyId}/allowedHosts/${domainId}`).then((data) => data.data)
  })
}

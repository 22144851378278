import instance from './client'
import { type PaginatedData, type Request, type RequestGroupsMap, type RequestType, type ResponseData } from '../types'
import { type AxiosError } from 'axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const useRequests = (params: QueryParamsRequest): UseQueryResult<ResponseData<Request<any>>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<Request<any>> & { company_name?: string; blog_id?: number }>({
    queryKey: ['requests', params.limit, params.offset, params?.company_id, params?.type, params?.request_id, params?.company_name, params?.blog_id],
    queryFn: async () => await instance.get('/requests', { params }).then((data) => data.data)
  })
}

export const useRequestGroups = (): UseQueryResult<RequestGroupsMap, AxiosError> => {
  return useQuery<unknown, AxiosError, RequestGroupsMap>({
    queryKey: ['request-groups'],
    queryFn: async () => await instance.get('/requests/groups').then((data) => data.data)
  })
}

interface QueryParamsRequest extends PaginatedData {
  type?: RequestType
  request_id?: number
  company_id?: number
  company_name?: string
  blog_id?: number
}

import instance from './client'
import { type BlogGSCHistoryRequest, type BlogGSCReports, type ResponseData } from '../types'
import { type AxiosError } from 'axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const useCompaniesGSCHistory = (params: BlogGSCHistoryRequest & { cs_manager?: number }): UseQueryResult<ResponseData<BlogGSCReports>, AxiosError> => {
  return useQuery<unknown, AxiosError, ResponseData<BlogGSCReports>>({
    queryKey: ['companies-gsc-history', params.offset, params.name, params.limit, params.cs_manager],
    queryFn: async () => await instance.get('/admin/gsc/list', { params }).then((data) => data.data)
  })
}

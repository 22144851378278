import * as React from 'react'
import { Box, FormControl, type InputBaseProps, InputLabel, MenuItem } from '@mui/material'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { CSStage } from '../../types'

interface CSStageSelectorProps {
  value?: CSStage
  label?: string
  onChange?: (value?: CSStage) => void
  size?: InputBaseProps['size']
  style?: React.CSSProperties
  allowEmpty?: boolean
  emptyValueText?: string
}

const CSStageOptions = [
  {
    label: 'Onboarding',
    value: CSStage.ONBOARDING
  },
  {
    label: 'Customer Success',
    value: CSStage.CUSTOMER_SUCCESS
  },
  {
    label: 'Stuck',
    value: CSStage.STUCK
  },
  {
    label: 'Fight',
    value: CSStage.FIGHT
  },
  {
    label: 'Churn',
    value: CSStage.CHURN
  }
]

const EMPTY_VALUE = '-'

const CSStageSelector: React.FC<CSStageSelectorProps> = ({ value, label, onChange = (v) => {}, size, style, allowEmpty = false, emptyValueText }) => {
  const finalLabel = label ?? 'CS Stage'
  const [currentStage, setCurrentStage] = React.useState(value ?? (allowEmpty ? EMPTY_VALUE : CSStage.ONBOARDING))

  React.useEffect(() => {
    setCurrentStage(value?.toString() ?? EMPTY_VALUE)
  }, [value])

  const labelConfig: Record<string, string> = {}

  if (size !== 'small') {
    labelConfig.labelId = 'company-cs-stage-select-label'
    labelConfig.label = finalLabel
  }

  const handleChange = (e: SelectChangeEvent): void => {
    if (e.target.value === EMPTY_VALUE) {
      onChange(undefined)
      setCurrentStage(EMPTY_VALUE)
    } else {
      onChange(e.target.value as CSStage)
      setCurrentStage(e.target.value as CSStage)
    }
  }

  return (
    <Box bgcolor="white" style={style ?? { width: '100%' }}>
      <FormControl fullWidth>
        {size !== 'small' && <InputLabel id="company-cs-stage-select-label">{finalLabel}</InputLabel>}
        <Select {...labelConfig} id="company-cs-stage-select" name="company-cs-stage-select" value={currentStage} onChange={handleChange} variant="outlined" color="primary" size={size ?? 'medium'} fullWidth={true}>
          {allowEmpty && <MenuItem value={EMPTY_VALUE}>{emptyValueText ?? '-'}</MenuItem>}
          {CSStageOptions.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CSStageSelector

import React, { useState } from 'react'
import { Autocomplete, Chip, TextField } from '@mui/material'

interface ValuesInputProps {
  values: string[]
  onChange: (newValues: string[]) => void
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string
  onValidate?: (value: string) => boolean
  disabled?: boolean
}

const ValuesInput: React.FC<ValuesInputProps> = ({ values, onChange, label, placeholder, error, helperText, onValidate, disabled }) => {
  const [inputValue, setInputValue] = useState('')

  // helper to preserve value
  const commitValue = (raw: string): void => {
    const candidate = raw.trim()
    if (!candidate) return

    // if there's a custom validator, use it
    if (onValidate && !onValidate(candidate)) return

    // removing duplicates from the array
    const newValues = new Set([...values, candidate])
    onChange(Array.from(newValues))
  }

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      disabled={disabled}
      value={values}
      inputValue={inputValue}
      onInputChange={(event, newValue, reason) => {
        // preserve value on Space clicked
        if (reason === 'input' && newValue.endsWith(' ')) {
          commitValue(newValue)
          setInputValue('')
        } else {
          setInputValue(newValue)
        }
      }}
      onBlur={() => {
        // when the focus is off - preserve value
        commitValue(inputValue)
        setInputValue('')
      }}
      onChange={(event, newValue) => {
        // updating values
        const cleaned = newValue.map((val) => val.trim()).filter(Boolean)
        onChange(Array.from(new Set(cleaned)))
      }}
      renderTags={(tagValues, getTagProps) => tagValues.map((option, index) => <Chip {...getTagProps({ index })} key={option} variant="outlined" label={option} />)}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} error={error} helperText={helperText} />}
    />
  )
}

export default ValuesInput

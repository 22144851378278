import React from 'react'
import { Stack, Typography } from '@mui/material'

interface EmptyProps {
  message?: string
  code?: number
  children?: React.ReactNode
}
const Empty: React.FC<EmptyProps> = (props) => (
  <Stack justifyContent="center" alignItems="center" padding={4}>
    <Typography>
      <h1>{props.code ?? '404'}</h1>
    </Typography>
    <Typography>
      <h3>{props.message ?? 'Resource is not found'}</h3>
    </Typography>
    {props.children}
  </Stack>
)

export default Empty

import { ROUTES, type SystemActionExtended, SystemActionType } from '../../types'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Pageview } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import moment from 'moment'
import { Link } from 'react-router-dom'

const formatPayload = (payload: string, type?: SystemActionType): string => {
  let payloadJson = null
  try {
    payloadJson = JSON.parse(payload)
  } catch (e) {
    console.error('Error parsing payload JSON:', e)
    return payload
  }

  switch (type) {
    case SystemActionType.TEMPLATE_UPDATE: {
      return `${payloadJson.provider} / ${payloadJson.key}`
    }
    case SystemActionType.CONFIG_UPDATE: {
      return (payloadJson?.attributes || []).join(', ')
    }
    default: {
      return payload
    }
  }
}

const columns: Array<ColumnDef<SystemActionExtended>> = [
  {
    accessorKey: 'id',
    header: 'Action Id'
  },
  {
    accessorKey: 'action_type',
    header: 'Action Type'
  },
  {
    accessorKey: 'user',
    header: 'User',
    cell: (cell) => {
      const user = cell.getValue() as any
      return (
        <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.USERS}/${user.id}`}>
          {user.email}
        </Link>
      )
    }
  },
  {
    accessorFn: (row) => JSON.stringify(row.payload, null, 2),
    header: 'Payload',
    cell: (cell) => <code style={{ maxHeight: 80, overflow: 'hidden' }}>{formatPayload(cell.getValue() as string, cell.row.getValue('action_type'))}</code>
  },
  {
    accessorFn: (row) => moment(row.created_at).format('YYYY/MM/DD HH:mm:ss'),
    header: 'Created At'
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.view?.(cell.getValue() as number)
          }}
        >
          <Pageview />
        </IconButton>
      </Box>
    )
  }
]

export default columns

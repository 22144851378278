import { type AuditLighthouseItem, type Blog, ROUTES } from '../../types'
import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'
import { type ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import { green, orange, red } from '@mui/material/colors'

const columns: Array<ColumnDef<Blog & { audit: AuditLighthouseItem | undefined | null }>> = [
  {
    accessorKey: 'id',
    header: 'Id'
  },
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'url',
    header: 'URL'
  },
  {
    accessorKey: 'company_id',
    header: 'Company Id',
    cell: (cell) => {
      const value = cell.getValue() as number
      return value ? (
        <Link target="_blank" rel="noopener noreferrer" to={`${ROUTES.COMPANIES}/${value}`}>
          {value}
        </Link>
      ) : (
        ''
      )
    }
  },
  {
    accessorKey: 'plugin_version',
    header: 'Plugin Version'
  },
  {
    accessorKey: 'audit',
    header: 'Lighthouse audit',
    cell: (cell) => {
      const value = cell.getValue() as AuditLighthouseItem | undefined | null
      if (!value) return '-'
      if (!value.assessments) return '-'

      const assessments = value.assessments

      const getScoreColor = (score: number | null | undefined): string => {
        if (score === null || score === undefined) return 'inherit'
        if (score >= 0.9) return green[500]
        if (score >= 0.5) return orange[500]
        return red[500]
      }

      const formatScore = (score: number | null): string => {
        if (score === null) return '-'
        const percentage = score * 100
        return Number.isInteger(percentage) ? percentage.toString() : percentage.toFixed(2)
      }

      const metrics = [
        { label: 'Performance', key: 'performance' },
        { label: 'Accessibility', key: 'accessibility' },
        { label: 'Best Practices', key: 'best-practices' },
        { label: 'SEO', key: 'seo' }
      ]

      return (
        <Box>
          {metrics.map((metric) => {
            const desktopValue = assessments[metric.key]?.desktop
            const mobileValue = assessments[metric.key]?.mobile
            const desktopScore = desktopValue != null ? Number(desktopValue) : null
            const mobileScore = mobileValue != null ? Number(mobileValue) : null

            return (
              <div style={{ whiteSpace: 'nowrap' }} key={metric.key}>
                {metric.label}: <span style={{ display: 'inline', color: getScoreColor(desktopScore) }}>{formatScore(desktopScore)}</span> / <span style={{ display: 'inline', color: getScoreColor(mobileScore) }}>{formatScore(mobileScore)}</span>
              </div>
            )
          })}
        </Box>
      )
    }
  },
  {
    accessorFn: (row) => moment(row.updated_at).format('YYYY/MM/DD HH:mm:ss'),
    header: 'Last Connection'
  },
  {
    accessorFn: (row) => row.id,
    header: 'Actions',
    cell: (cell) => (
      <Box>
        <IconButton
          onClick={() => {
            cell.table.options.meta?.edit?.(cell.getValue() as number)
          }}
        >
          <Edit />
        </IconButton>
      </Box>
    )
  }
]

export default columns

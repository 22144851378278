import React, { useContext, useState } from 'react'
import MessageContext from '../../../../contexts/MessageContext'
import { useContentAlertDelays, useResetContentAlertDelays } from '../../../../api/contentAlert'
import { locale } from '../../../../constants'
import Spinner from '../../../../components/spinner/Spinner'
import { type ContentAlertDelay } from '../../../../types'
import { Box, Button, Stack, Table, TableHead, TableBody, TableRow, TableCell, Typography, CircularProgress, TablePagination } from '@mui/material'
import moment from 'moment'

interface ContentAlertDelaysProps {
  delays: ContentAlertDelay[]
}

const ContentAlertDelaysInfo: React.FC<ContentAlertDelaysProps> = ({ delays }) => {
  // simple pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  // calculating current page data
  const startIndex = page * rowsPerPage
  const currentPageDelays = delays.slice(startIndex, startIndex + rowsPerPage)

  // handlers for pagination
  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  return (
    <Stack spacing={3} marginTop={2}>
      <Box display="flex">
        <Stack flex={4}>
          {!!delays.length && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">
                      <strong>URL</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Created At</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Expires At (In)</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageDelays.map((delay, idx) => {
                    const expiresMoment = moment(delay.expires_at)
                    const expiresIn = expiresMoment.fromNow()

                    return (
                      <TableRow key={idx}>
                        <TableCell>
                          <a target="_blank" rel="noopener noreferrer" href={delay.url}>
                            {delay.url}
                          </a>
                        </TableCell>
                        <TableCell>{moment(delay.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                        <TableCell>
                          {expiresMoment.format('YYYY-MM-DD HH:mm:ss')} ({expiresIn})
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
              <TablePagination component="div" count={delays.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} rowsPerPageOptions={[5, 10, 25, 50]} />
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useContentAlertDelays(blogId)
  const resetDelays = useResetContentAlertDelays()
  const addMessage = useContext(MessageContext)

  const handleResetDelays = async (): Promise<void> => {
    await resetDelays
      .mutateAsync({ blogId })
      .then(() => {
        addMessage({
          id: Date.now(),
          message: locale.response.success,
          severity: 'success'
        })
      })
      .catch((error) => {
        addMessage({
          id: Date.now(),
          message: error?.response?.data?.error || error?.message || 'Error resetting delays',
          severity: 'error'
        })
      })
  }

  const getContent = (): React.ReactElement => {
    if (isLoading) return <Spinner marginY={8} />

    // if delays exist, showing the delays form
    if (data?.length) {
      return <ContentAlertDelaysInfo delays={data} />
    }

    // no delays found
    const message = ((error?.response?.data as any)?.error ?? error?.message ?? 'No delays found') as string
    return (
      <Stack justifyContent="center" alignItems="center" marginY={4}>
        <Typography color="#999">{message}</Typography>
      </Stack>
    )
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Alert Delays</Typography>
        <Button
          disabled={!data?.length || resetDelays.isLoading}
          variant="outlined"
          color="primary"
          onClick={() => {
            handleResetDelays()
          }}
        >
          {resetDelays.isLoading && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Reset
        </Button>
      </Box>
      {getContent()}
    </div>
  )
}

export default Container

import React, { useContext } from 'react'
import { useConfig, useUpdateConfig } from '../../api/config'
import Loader from '../../components/loader/Loader'
import { AI_PROVIDER, type Config as ConfigType, ConfigField, FluxAPIVersion, ROUTES } from '../../types'
import css from './Config.module.css'
import { useFormik } from 'formik'
import { Navigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { Button, FormHelperText } from '@mui/material'
import MessageContext from '../../contexts/MessageContext'
import { locale } from '../../constants'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import Selector from '../../components/selector/Selector'
import { AI_MODELS_BY_PROVIDER } from '../../components/aiModelSelector/AIModelSelector'
import * as Yup from 'yup'

interface ConfigProps {
  config: ConfigType
}

const getFieldPath = (label: string): string => `config.${label}`

const configSchema = Yup.object({
  config: Yup.object({
    ...Object.keys(ConfigField).reduce<Record<any, any>>((acc, field) => {
      acc[field] = Yup.string()
      return acc
    }, {}),
    [ConfigField.DEFAULT_AI_PROVIDER]: Yup.string().oneOf(Object.values(AI_PROVIDER)).required(),
    [ConfigField.DEFAULT_AI_MODEL]: Yup.string().required(),
    [ConfigField.DEFAULT_AI_TEMPERATURE]: Yup.number().required().min(0).max(1)
  })
})

const Config: React.FC<ConfigProps> = ({ config }) => {
  const updateConfig = useUpdateConfig()
  const addMessage = useContext(MessageContext)
  const fields = Object.keys(config.config).sort() as ConfigField[]

  const formik = useFormik<ConfigType>({
    initialValues: config,
    validationSchema: configSchema,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true)
      updateConfig
        .mutateAsync({
          id: config.id,
          ...values.config
        })
        .catch((err) => {
          addMessage({
            id: Date.now(),
            message: err.response.data?.message || err.response.data || err.message,
            code: err.response.status
          })
        })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .finally(() => {
          formikHelpers.setSubmitting(false)
        })
    }
  })

  const getFieldInput = (field: ConfigField): React.JSX.Element => {
    if (field === ConfigField.DEFAULT_AI_PROVIDER) {
      const options = Object.entries(AI_PROVIDER).map(([label, value]) => ({
        value,
        label
      }))

      return (
        <Selector
          key={field}
          label={field}
          options={options}
          value={formik.values.config[field] as AI_PROVIDER}
          onChange={(value: any) => {
            formik.setFieldValue(getFieldPath(field), value)
            formik.setFieldValue(getFieldPath(ConfigField.DEFAULT_AI_MODEL), '')
          }}
          disabled={formik.isSubmitting}
          error={!!formik.touched.config?.[field] && !!formik.errors.config?.[field]}
          helperText={formik.touched.config?.[field] && formik.errors.config?.[field]}
          selectProps={{
            onBlur: () => {
              formik.setFieldTouched(getFieldPath(field), true)
            }
          }}
        />
      )
    }

    if (field === ConfigField.DEFAULT_AI_MODEL) {
      const selectedProvider = formik.values.config[ConfigField.DEFAULT_AI_PROVIDER] as AI_PROVIDER

      const options = Object.entries(AI_MODELS_BY_PROVIDER[selectedProvider] || {}).map(([value, label]) => ({
        value,
        label
      }))

      return (
        <Selector
          key={field}
          label={field}
          options={options}
          value={formik.values.config[field] as AI_PROVIDER}
          onChange={(value: any) => {
            formik.setFieldValue(getFieldPath(field), value)
          }}
          disabled={formik.isSubmitting}
          error={!!formik.touched.config?.[field] && !!formik.errors.config?.[field]}
          helperText={formik.touched.config?.[field] && formik.errors.config?.[field]}
          selectProps={{
            onBlur: () => {
              formik.setFieldTouched(getFieldPath(field), true)
            }
          }}
        />
      )
    }

    if (field === ConfigField.FLUX_API_VERSION) {
      const options = Object.entries(FluxAPIVersion).map(([label, value]) => ({
        value,
        label
      }))

      return (
        <Selector
          key={field}
          label={field}
          options={options}
          value={formik.values.config[field] as FluxAPIVersion}
          onChange={(value: any) => {
            formik.setFieldValue(getFieldPath(field), value)
          }}
          disabled={formik.isSubmitting}
          error={!!formik.touched.config?.[field] && !!formik.errors.config?.[field]}
          helperText={formik.touched.config?.[field] && formik.errors.config?.[field]}
          selectProps={{
            onBlur: () => {
              formik.setFieldTouched(getFieldPath(field), true)
            }
          }}
        />
      )
    }

    return (
      <TextField
        disabled={formik.isSubmitting}
        key={field}
        fullWidth
        id={field}
        name={getFieldPath(field)}
        label={field}
        value={formik.values.config[field]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.touched.config?.[field] && !!formik.errors.config?.[field]}
        helperText={formik.touched.config?.[field] && formik.errors.config?.[field]}
      />
    )
  }

  const validationError = Object.values(formik.errors?.config ?? {})[0]

  return (
    <div className={css.container}>
      {formik.isSubmitting && <Loader />}
      <PageHeaderText>Config</PageHeaderText>
      <form onSubmit={formik.handleSubmit} className={css.form}>
        {fields.map((field) => getFieldInput(field))}
        <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting || !!validationError}>
          Save
        </Button>
        {!!validationError && (
          <FormHelperText error={true} style={{ textAlign: 'center' }}>
            {validationError}
          </FormHelperText>
        )}
      </form>
    </div>
  )
}

const Container: React.FC = () => {
  const { data: config, isLoading } = useConfig()

  if (isLoading) return <Loader />
  if (config) return <Config config={config} />
  return <Navigate to={ROUTES.INDEX} />
}
export default Container

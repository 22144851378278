import { useMutation, type UseMutationResult, useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import instance from './client'
import { type ContentAlertConfig, type ContentAlertConfigUpdate, type ContentAlertDelay } from '../types'

export const useContentAlertConfig = (blogId?: number): UseQueryResult<ContentAlertConfig | undefined, AxiosError> => {
  return useQuery<unknown, AxiosError, ContentAlertConfig | undefined>({
    queryKey: ['content-alert-config', blogId],
    queryFn: async () => await instance.get('/content-alerts/config', { params: { blogId } }).then((response) => response.data.data),
    enabled: !!blogId
  })
}

export const useUpdateContentAlertConfig = (): UseMutationResult<ContentAlertConfig, AxiosError, { blogId: number; data: ContentAlertConfigUpdate }> => {
  const queryClient = useQueryClient()
  return useMutation<ContentAlertConfig, AxiosError, { blogId: number; data: ContentAlertConfigUpdate }>({
    mutationKey: ['update-content-alert-config'],
    mutationFn: async ({ blogId, data: input }) =>
      await instance
        .post(
          '/content-alerts/config',
          {
            data: input
          },
          { params: { blogId } }
        )
        .then((response) => response.data.data),
    onSuccess: (data) => {
      queryClient.setQueryData(['content-alert-config', data.blog_id], data)
    }
  })
}

export const useContentAlertDelays = (blogId?: number): UseQueryResult<ContentAlertDelay[], AxiosError> => {
  return useQuery<unknown, AxiosError, ContentAlertDelay[]>({
    queryKey: ['content-alert-delays', blogId],
    queryFn: async () => await instance.get('/content-alerts/delays', { params: { blogId } }).then((response) => response.data.data),
    enabled: !!blogId
  })
}

export const useResetContentAlertDelays = (): UseMutationResult<ContentAlertDelay[], AxiosError, { blogId: number }> => {
  const queryClient = useQueryClient()
  return useMutation<ContentAlertDelay[], AxiosError, { blogId: number }>({
    mutationKey: ['reset-content-alert-delays'],
    mutationFn: async ({ blogId }) => await instance.post('/content-alerts/delays/reset', undefined, { params: { blogId } }).then((response) => response.data.data),
    onSuccess: (data, input) => {
      queryClient.invalidateQueries(['content-alert-delays', input.blogId])
    }
  })
}

import React from 'react'
import ContentAlertsConfig from './components/ContentAlertConfig'
import ContentAlertDelays from './components/ContentAlertDelays'
import { Box, Grid } from '@mui/material'

interface ContentAlertsProps {
  blogId: number
}

const ContentAlerts: React.FC<ContentAlertsProps> = ({ blogId }) => {
  return (
    <Box display="flex">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Box>
            <ContentAlertsConfig blogId={blogId} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <ContentAlertDelays blogId={blogId} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentAlerts

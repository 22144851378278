import React, { useContext, useState } from 'react'
import MessageContext from '../../../../contexts/MessageContext'
import { useRemoveReport, useReports, useSendReport } from '../../../../api/report'
import { locale } from '../../../../constants'
import Spinner from '../../../../components/spinner/Spinner'
import { ReportStatuses, type Report } from '../../../../types'
import { Box, Button, Stack, Table, TableHead, TableBody, TableRow, TableCell, Typography, CircularProgress, TablePagination, IconButton } from '@mui/material'
import moment from 'moment'
import { lightBlue, orange, red, teal, yellow } from '@mui/material/colors'
import { DeleteRounded, PictureAsPdf } from '@mui/icons-material'

interface ReportRowProps {
  report: Report
}

const ColorsOfStatues: Record<string, string> = {
  created: yellow.A700,
  inprogress: orange.A700,
  completed: teal.A700,
  sent: lightBlue.A700,
  error: red.A700
}

const ReportRow: React.FC<ReportRowProps> = ({ report }) => {
  const sendReport = useSendReport()
  const removeReport = useRemoveReport()
  const addMessage = useContext(MessageContext)
  const handleSendReport = async (id: number): Promise<void> => {
    await sendReport
      .mutateAsync({ id })
      .then(() => {
        addMessage({
          id: Date.now(),
          message: locale.response.success,
          severity: 'success'
        })
      })
      .catch((error) => {
        addMessage({
          id: Date.now(),
          message: error?.response?.data?.error || error?.message || 'Sending report error',
          severity: 'error'
        })
      })
  }
  const handleRemoveReport = async (id: number): Promise<void> => {
    await removeReport
      .mutateAsync({ id })
      .then(() => {
        addMessage({
          id: Date.now(),
          message: locale.response.success,
          severity: 'success'
        })
      })
      .catch((error) => {
        addMessage({
          id: Date.now(),
          message: error?.response?.data?.error || error?.message || 'Removing report error',
          severity: 'error'
        })
      })
  }

  return (
    <TableRow>
      <TableCell>{report.title}</TableCell>
      <TableCell>{moment(report.start_date).format('YYYY-MM')}</TableCell>
      <TableCell>{moment(report.created_at).format('YYYY-MM-DD HH:mm')}</TableCell>
      <TableCell sx={{ color: ColorsOfStatues[report.report_status] ?? 'black' }}>{report.report_status}</TableCell>
      <TableCell>
        {report.file ? (
          <IconButton component="a" target="_blank" href={report.file} download={`${report.title}.pdf`} color="primary">
            <PictureAsPdf />
          </IconButton>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>
        <Button
          disabled={sendReport.isLoading || (report.report_status !== ReportStatuses.COMPLETED && report.report_status !== ReportStatuses.SENT)}
          variant="outlined"
          color="primary"
          onClick={() => {
            handleSendReport(report.id)
          }}
        >
          {sendReport.isLoading && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Send
        </Button>
      </TableCell>
      <TableCell>
        <IconButton
          component="button"
          disabled={removeReport.isLoading}
          color="primary"
          onClick={() => {
            handleRemoveReport(report.id)
          }}
        >
          <DeleteRounded />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

interface ReportListProps {
  reports: Report[]
}

const ReportList: React.FC<ReportListProps> = ({ reports }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const startIndex = page * rowsPerPage
  const currentPageReports = reports.slice(startIndex, startIndex + rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  return (
    <Stack spacing={3} marginTop={2}>
      <Box display="flex">
        <Stack flex={4}>
          {!!reports.length && (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="35%">
                      <strong>Title</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Month</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Created At</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Status</strong>
                    </TableCell>
                    <TableCell>
                      <strong>File</strong>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageReports.map((report, idx) => {
                    return <ReportRow key={idx} report={report} />
                  })}
                </TableBody>
              </Table>
              <TablePagination component="div" count={reports.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} rowsPerPageOptions={[5, 10, 25, 50]} />
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  const { data, isLoading, error } = useReports(blogId)

  const getContent = (): React.ReactElement => {
    if (isLoading) return <Spinner marginY={8} />

    if (data?.length) {
      return <ReportList reports={data} />
    }

    const message = ((error?.response?.data as any)?.error ?? error?.message ?? 'No reports found') as string
    return (
      <Stack justifyContent="center" alignItems="center" marginY={4}>
        <Typography color="#999">{message}</Typography>
      </Stack>
    )
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Reports</Typography>
      </Box>
      {getContent()}
    </div>
  )
}

export default Container

import React, { useContext } from 'react'
import MessageContext from '../../../../contexts/MessageContext'
import { type ReportInit } from '../../../../types'
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { type FormikProps, useFormik } from 'formik'
import { useStartReportCreation } from '../../../../api/report'
import { locale } from '../../../../constants'
import * as Yup from 'yup'
import moment from 'moment'
import DatePicker from '../../../../components/datePicker/DatePicker'

const editableReportConfigSchema = Yup.object({
  start_date: Yup.string().required('Required')
})

const StartReportCreation: React.FC<{ blogId: number }> = ({ blogId }) => {
  const startReportCreation = useStartReportCreation()
  const addMessage = useContext(MessageContext)

  const formik: FormikProps<ReportInit> = useFormik<ReportInit>({
    initialValues: {
      blog_id: blogId,
      start_date: moment().subtract(1, 'months').toISOString()
    },
    validationSchema: editableReportConfigSchema,
    onSubmit: (values, helpers) => {
      helpers.setSubmitting(true)
      startReportCreation
        .mutateAsync({ blogId, data: values })
        .then(() => {
          addMessage({
            id: Date.now(),
            message: locale.response.success,
            severity: 'success'
          })
        })
        .catch((err: any) => {
          addMessage({
            id: Date.now(),
            message: err.response?.data?.error || err.message
          })
        })
        .finally(() => {
          helpers.setSubmitting(false)
        })
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box display="flex" sx={{ gap: '1rem', alignItems: 'center' }}>
        <Typography variant="h6">Monthly Report</Typography>
        <DatePicker
          name="dateFrom"
          label="Month"
          value={moment(formik.values.start_date).toDate()}
          maxDate={moment().subtract(1, 'months').toDate()}
          onChange={(v) => formik.setFieldValue('start_date', moment(v).toISOString()) as any}
          dateFormat={'MM/yyyy'}
          showMonthYearPicker={true}
        />
        <Button disabled={formik.isSubmitting} type="submit" variant="contained" color="primary" sx={{ width: '250px' }}>
          {formik.isSubmitting && <CircularProgress color="inherit" size={'1rem'} sx={{ marginRight: '0.5rem' }} />}Run
        </Button>
      </Box>
    </form>
  )
}

const Container: React.FC<{ blogId: number }> = ({ blogId }) => {
  return (
    <Box sx={{ width: '100%', marginBottom: '60px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: '30px' }}>
        <Typography variant="h5">Run Report Creation</Typography>
      </Box>
      <Box>
        <StartReportCreation blogId={blogId} />
      </Box>
    </Box>
  )
}

export default Container

import React, { useContext, useState } from 'react'
import css from './Companies.module.css'
import { useCompanies, useRemoveCompany } from '../../api/company'
import { Box, Button, FormControl, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { type Company, type CSStage, ManagementRole, ROUTES } from '../../types'
import columns from './Columns'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Dialog from '../../components/dialog/Dialog'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MessageContext from '../../contexts/MessageContext'
import { locale } from '../../constants'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import ManagerSelector from '../../components/managerSelector/ManagerSelector'
import CSStageSelector from '../../components/csStageSelector/CSStageSelector'

const LIMIT = 20

interface TableProps {
  data: Company[]
  setToRemove: (id: number) => void
}
const TableCompanies: React.FC<TableProps> = ({ data, setToRemove }) => {
  const navigate = useNavigate()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      keywords: (id: number) => {
        navigate(`${ROUTES.COMPANIES}/${id}${ROUTES.KEYWORDS}`)
      },
      requests: (id: number) => {
        navigate(`${ROUTES.REQUESTS}/?company_id=${id}`)
      },
      edit: (id: number) => {
        navigate(`${ROUTES.COMPANIES}/${id}`)
      },
      remove: (id: number) => {
        setToRemove(id)
      }
    }
  })
  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const Companies: React.FC = (props) => {
  const [searchParams] = useSearchParams()
  const addMessage = useContext(MessageContext)
  const [toRemove, setToRemove] = useState<Company | undefined>()
  const removeCompany = useRemoveCompany()
  const [limit, setLimit] = useState<number>(LIMIT)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const initialCsManager = searchParams.has('cs_manager') ? Number(searchParams.get('cs_manager')) : undefined
  const [csManager, setCsManager] = useState<number | undefined>(isNaN(initialCsManager as any) ? undefined : initialCsManager)

  const initialCsStage = searchParams.get('cs_stage') as CSStage
  const [csStage, setCSStage] = useState<CSStage | undefined>(initialCsStage ?? undefined)

  const {
    data: { data, total } = { data: [] as Company[], total: 0 },
    isLoading,
    error
  } = useCompanies({
    limit,
    offset: (page - 1) * limit,
    search,
    cs_manager: csManager,
    cs_stage: csStage
  })

  return (
    <div className={css.container}>
      <PageHeaderText>Companies</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput}>
          <TextField
            id="outlined-basic"
            label={'Name to search'}
            variant="outlined"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <ManagerSelector role={ManagementRole.CS_MANAGER} style={{ marginLeft: '15px', width: '200px' }} emptyValueText="-" onChange={(v) => setCsManager(v)} value={csManager} customOptions={[{ id: 0, email: 'Without manager' }]} />
          <CSStageSelector style={{ marginLeft: '15px', width: '200px' }} onChange={(v) => setCSStage(v)} value={csStage} allowEmpty={true} emptyValueText={'-'} />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setSearch('')
              setCsManager(undefined)
              setCSStage(undefined)
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Companies per page</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={limit}
              label="Companies per page"
              onChange={(e) => {
                setLimit(Number(e.target.value))
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableCompanies
        data={data}
        setToRemove={(id) => {
          setToRemove(data.find((d) => d.id === id))
        }}
      />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
      <Dialog
        open={!!toRemove}
        handleClose={() => {
          setToRemove(undefined)
        }}
        content={`This action removes this company: ${toRemove?.name}. All related data (user, content, requests etc.) will be removed`}
        title="Remove Company?"
        handleOk={() => {
          toRemove?.id &&
            removeCompany
              .mutateAsync({ id: toRemove.id })
              .then(() => {
                addMessage({ id: Date.now(), message: locale.response.success, severity: 'success' })
              })
              .catch((err) => {
                addMessage({ id: Date.now(), message: err.response.data || err.message })
              })
              .finally(() => {
                setToRemove(undefined)
              })
        }}
      />
    </div>
  )
}

export default Companies

import { useMemo, useState } from 'react'
import { type AI_PROVIDER, type Template, type TemplatesUpdateData } from '../../types'
import { useUpdateTemplatesByProvider } from '../../api/template'
import { defaultFieldGroup, fieldGroupsByTab, type TemplateFieldGroup } from './fieldGroups'
import _ from 'lodash'
import { type UseMutationResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

export type UseTemplate = {
  data?: Record<string, Template>
  fields: string[]
  selectedTab: number
  groupedFields: GroupedFields[]
  tabNames: string[]
  tabFieldsCount: Record<string, number>
  updateTemplates: UseMutationResult<Template[], AxiosError, TemplatesUpdateData>
  switchTab: (newTabIdx: number) => void
  filter?: string
}

type UseTemplateProps = {
  provider: AI_PROVIDER
  data?: Template[]
  filter?: string
}

type GroupedFields = {
  group: TemplateFieldGroup
  fields: string[]
}

type Tab = {
  name: string
  match?: (fieldKey: string) => boolean
}

const tabsNamesList: Tab[] = [
  { name: 'general' },
  { name: 'content' },
  { name: 'frame' },
  { name: 'ideas' },
  { name: 'ideas_prompt', match: (fieldKey) => fieldKey.startsWith('ideas_prompt') },
  { name: 'keywords' },
  { name: 'page' },
  { name: 'postv2' },
  { name: 'post' },
  { name: 'post_optimization', match: (fieldKey) => fieldKey.startsWith('post_optimize') },
  { name: 'mass_actions', match: (fieldKey: string) => ['post_translate', 'post_update', 'post_review'].some((v) => fieldKey.startsWith(v)) },
  { name: 'audit', match: (fieldKey: string) => fieldKey.startsWith('audit') }
]

const useTemplate = ({ data, filter, provider }: UseTemplateProps): UseTemplate => {
  const updateTemplates = useUpdateTemplatesByProvider(provider)
  const [selectedTab, setSelectedTab] = useState(0)
  const mappedData = useMemo(() => _.keyBy(Array.isArray(data) ? data : [], 'key'), [data])

  const tabNames = useMemo<string[]>(() => tabsNamesList.map((tab) => tab.name), [])

  const fields = useMemo<string[]>(() => {
    return Object.keys(mappedData)
  }, [mappedData])

  const fieldsByTabMap = useMemo<Record<string, string[]>>(() => {
    const result: Record<string, string[]> = {}
    const remainingFields = [...fields]

    // initialize result object with empty arrays for each tab
    tabsNamesList.forEach((tab) => {
      result[tab.name] = []
    })

    // iterating through tabs in reverse order and checking match methods
    ;[...tabsNamesList].reverse().forEach((tab) => {
      if (tab.name === 'general') return

      const matchedFields = remainingFields.filter((field: string) => tab.match?.(field.toLowerCase()))
      result[tab.name].push(...matchedFields)
      _.pullAll(remainingFields, matchedFields)
    })

    // iterating through tabs in reverse order and checking field prefixes
    ;[...tabsNamesList].reverse().forEach((tab) => {
      if (tab.name === 'general') return

      const matchedFields = remainingFields.filter((field: string) => field.toLowerCase().startsWith(`${tab.name}_`))
      result[tab.name].push(...matchedFields)
      _.pullAll(remainingFields, matchedFields)
    })

    // Add remaining fields to the 'general' tab
    result.general.push(...remainingFields)

    return result
  }, [fields])

  // filter fields by tab
  const filteredFieldsByTabMap = useMemo<Record<string, string[]>>(() => {
    return Object.entries(fieldsByTabMap).reduce((acc, [tabName, tabFields]) => {
      const filteredFields = filter ? tabFields.filter((key) => key.toLowerCase().includes(filter.toLowerCase() || '')) : tabFields

      return {
        ...acc,
        [tabName]: filteredFields
      }
    }, {})
  }, [fieldsByTabMap, selectedTab, filter])

  // calculating how many fields are found for each tab
  const tabFieldsCount = useMemo(() => {
    return Object.entries(filteredFieldsByTabMap).reduce((acc, [tabName, tabFields]) => {
      return {
        ...acc,
        [tabName]: tabFields.length
      }
    }, {})
  }, [filteredFieldsByTabMap])

  // filter fields to display based on active tab & search term
  const filteredFields = filteredFieldsByTabMap[tabsNamesList[selectedTab].name]

  // get field groups for active tab
  const fieldGroups = useMemo<TemplateFieldGroup[]>(
    () =>
      [...(fieldGroupsByTab[tabsNamesList[selectedTab].name] || []), defaultFieldGroup]
        .reverse()
        .map((group, groupIndex) => ({ ...group, color: groupIndex ? '#9575cd' : '#bdbdbd' }))
        .reverse(),
    [fields, selectedTab]
  )

  // grouping fields
  let fieldsCounter = [...filteredFields]
  const groupedFields = useMemo<GroupedFields[]>((): GroupedFields[] => {
    return fieldGroups.map((fGroup: TemplateFieldGroup): GroupedFields => {
      const groupFields: string[] = []
      fieldsCounter = fieldsCounter.filter((field) => {
        if (fGroup.test(field)) {
          groupFields.push(field)
          return false
        }
        return true
      })

      return { group: fGroup, fields: groupFields }
    })
  }, [filteredFields, fieldGroups])

  return {
    data: mappedData,
    fields: filteredFields,
    groupedFields,
    selectedTab,
    tabNames,
    tabFieldsCount,
    updateTemplates,
    filter,
    switchTab: setSelectedTab
  }
}

export default useTemplate

import React, { useMemo, useState } from 'react'
import css from './SystemActions.module.css'
import { Box, Button, FormControl, InputLabel, MenuItem, Pagination, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { type SystemAction, type SystemActionExtended, SystemActionType } from '../../types'
import columns from './Columns'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import Dialog from '../../components/dialog/Dialog'
import { useSystemActions } from '../../api/systemAction'
import { useSearchParams } from 'react-router-dom'
import PageHeaderText from '../../components/pageHeader/PageHeaderText'
import JSONView from '../../components/jsonView/JSONView'

const LIMIT_DEFAULT = 5

interface TableProps {
  data: SystemActionExtended[]
  setToView: (id: number) => void
}

type SearchProps = {
  action_id: number | null
  action_type: SystemActionType | undefined | ''
  user_id: number | null
  user_email: string | null
}

const SEARCH_EMPTY_VALUES: SearchProps = {
  action_id: null,
  action_type: '',
  user_id: null,
  user_email: ''
}

const TableSystemActions: React.FC<TableProps> = ({ data, setToView }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      view: (id: number) => {
        setToView(id)
      }
    }
  })

  return (
    <Table sx={{ maxWidth: '100%' }}>
      <TableHead>
        {table.getHeaderGroups().map((hg) => (
          <TableRow key={hg.id}>
            {hg.headers.map((h) => (
              <TableCell key={h.index}>{flexRender(h.column.columnDef.header, h.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id} className={css.row}>
            {row.getVisibleCells().map((cell) => {
              const isExpandable = ['payload'].includes(((cell.column?.columnDef?.header as string) || '').toLowerCase())
              const columnMaxWidth = isExpandable ? '1px' : undefined
              const columnWidth = isExpandable ? '25%' : undefined

              return (
                <TableCell sx={{ maxWidth: columnMaxWidth, width: columnWidth }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const SystemActions: React.FC = () => {
  const [toView, setToView] = useState<SystemAction | undefined>()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState<number>(LIMIT_DEFAULT)
  const [clearedFormKey, setClearedForm] = useState<number>(0)
  const searchInitialValues = useMemo(() => {
    return {
      ...SEARCH_EMPTY_VALUES
    }
  }, [])
  const [search, setSearch] = useState<SearchProps>({
    ...searchInitialValues
  })
  const {
    data: { data, total } = { data: [] as SystemActionExtended[], total: 0 },
    isLoading,
    error
  } = useSystemActions({
    limit,
    offset: (page - 1) * limit,
    ...Object.entries(search).reduce((acc, [key, value]) => {
      if (!value) {
        return acc
      }
      if (typeof value === 'string' && value.length) {
        return { ...acc, [key]: value.trim() }
      }
      return { ...acc, [key]: value }
    }, {})
  })

  const setDefaultSearch = (): void => {
    setSearch({ ...searchInitialValues })
    setClearedForm(Math.random())
  }
  const setSearchParam = (param: string, value: string | number | null): void => {
    setSearch({ ...search, [param]: value })
  }

  return (
    <div className={css.container}>
      <PageHeaderText>System Actions</PageHeaderText>
      <div className={css.search}>
        <div className={css.searchInput} key={clearedFormKey}>
          <FormControl fullWidth style={{ width: '150px' }}>
            <InputLabel id="select-label">Action Type</InputLabel>
            <Select
              labelId="select-label_rq_type"
              id="system-action-type-filter"
              value={search.action_type}
              label="Action Type"
              onChange={(e) => {
                setSearchParam('action_type', e.target.value)
              }}
            >
              {Object.values(SystemActionType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="action-id-filter"
            style={{ marginLeft: '15px' }}
            label={'Action Id'}
            variant="outlined"
            value={search.action_id ?? ''}
            onChange={(e) => {
              setSearchParam('action_id', e.target.value)
            }}
          />
          <TextField
            id="user-id-filter"
            style={{ marginLeft: '15px' }}
            label={'User Id'}
            variant="outlined"
            value={search.user_id ?? ''}
            onChange={(e) => {
              setSearchParam('user_id', e.target.value)
            }}
          />
          <TextField
            id="user-email-filter"
            style={{ marginLeft: '15px' }}
            label={'User Email'}
            variant="outlined"
            value={search.user_email}
            onChange={(e) => {
              setSearchParam('user_email', e.target.value)
            }}
          />
          <Button
            className={css.searchButton}
            style={{ marginLeft: '15px' }}
            variant="outlined"
            onClick={() => {
              setDefaultSearch()
            }}
          >
            Clear search
          </Button>
        </div>
        <div className={css.search_cpp}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Actions per page</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              value={limit}
              label="Actions per page"
              onChange={(e) => {
                setLimit(Number(e.target.value))
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <TableSystemActions
        data={data}
        setToView={(id) => {
          setToView(data.find((d) => d.id === id))
        }}
      />
      <Box display="flex" marginTop={1} justifyContent="flex-end">
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          onChange={(e, v) => {
            setPage(v)
          }}
        />
      </Box>
      <Dialog
        fullWidth
        open={!!toView}
        handleClose={() => {
          setToView(undefined)
        }}
        content={<Content data={toView} />}
        title={`Action Item: #${toView?.id.toString()}`}
        handleOk={() => {
          setToView(undefined)
        }}
        classes={{ paper: css.dialog_window }}
      />
    </div>
  )
}

const Content: React.FC<{ data?: SystemAction }> = (params) => {
  return (
    <Stack className={css.actions_style}>
      <Typography variant="h5">Payload</Typography>
      <div className={css.code}>
        <JSONView src={params.data?.payload ?? {}} />
      </div>
    </Stack>
  )
}
export default SystemActions

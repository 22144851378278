import React, { forwardRef, type FC } from 'react'
import ReactDatePicker from 'react-datepicker'
import TextField, { type TextFieldProps } from '@mui/material/TextField'
import 'react-datepicker/dist/react-datepicker.css'
import { Box } from '@mui/material'

interface DatePickerProps {
  label?: string
  name?: string
  disabled?: boolean
  onBlur?: (e: any) => void
  error?: boolean
  helperText?: string
  onChange: (date: Date | null) => void
  value: Date | null
  popperPlacement?: string
  dateFormat?: string
  showMonthYearPicker?: boolean
  maxDate?: Date
}

const CustomDPInput = forwardRef<HTMLInputElement, TextFieldProps>(({ name, label, value, onClick, error, disabled }, ref) => (
  <TextField
    ref={ref}
    error={error}
    name={name}
    value={value}
    onClick={onClick}
    label={label}
    disabled={disabled}
    variant="outlined"
    fullWidth
    InputProps={{
      readOnly: true
    }}
  />
))
CustomDPInput.displayName = 'CustomDPInput'

const DatePicker: FC<DatePickerProps> = ({ label, value, onChange, onBlur, name, disabled, error, helperText, popperPlacement, dateFormat = 'dd/MM/yyyy', maxDate, showMonthYearPicker = false }) => {
  return (
    <Box display="flex" flexDirection="column" position="relative" zIndex="999">
      <ReactDatePicker
        popperPlacement={popperPlacement as any}
        dateFormat={dateFormat}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        selected={value}
        onChange={onChange}
        showMonthYearPicker={showMonthYearPicker}
        {...(maxDate ? { maxDate } : {})}
        customInput={<CustomDPInput label={label} error={error} helperText={helperText} />}
      />
    </Box>
  )
}

export default DatePicker

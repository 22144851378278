import React from 'react'
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, type SelectChangeEvent, type SelectProps } from '@mui/material'

interface SelectorOption<T> {
  value: T
  label: string
}

interface SelectorProps<T> {
  label?: string
  options: Array<SelectorOption<T>>
  value?: T
  defaultValue?: T
  onChange?: (value?: T) => void
  disabled?: boolean
  allowEmpty?: boolean
  emptyValueText?: string
  style?: React.CSSProperties
  selectProps?: Partial<Omit<SelectProps<string>, 'onChange' | 'value'>>
  error?: boolean
  helperText?: React.ReactNode
}

const EMPTY_VALUE = '-'

const Selector = <T extends string>({ label = 'Select an option', options, value, defaultValue, onChange, disabled, allowEmpty, emptyValueText = '-', style, selectProps, error, helperText }: SelectorProps<T>): React.JSX.Element => {
  const selectedValue = value ?? defaultValue ?? (allowEmpty ? EMPTY_VALUE : options[0]?.value) ?? ''

  const handleSelectChange = (event: SelectChangeEvent): void => {
    const newValue = event.target.value === EMPTY_VALUE ? undefined : (event.target.value as T)
    onChange?.(newValue)
  }

  return (
    <Box style={style ?? { width: '100%' }}>
      <FormControl fullWidth disabled={disabled} error={Boolean(error)}>
        <InputLabel>{label}</InputLabel>
        <Select label={label} value={selectedValue} onChange={handleSelectChange} {...selectProps}>
          {allowEmpty && <MenuItem value={EMPTY_VALUE}>{emptyValueText}</MenuItem>}
          {options.map(({ value: itemValue, label: itemLabel }) => (
            <MenuItem key={String(itemValue)} value={itemValue}>
              {itemLabel}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default Selector

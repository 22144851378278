import React from 'react'
import ReportsConfig from './components/ReportConfig'
import ReportList from './components/ReportList'
import ReportCreation from './components/ReportCreation'
import { Box, Grid } from '@mui/material'

interface ReportsProps {
  blogId: number
}

const Reports: React.FC<ReportsProps> = ({ blogId }) => {
  return (
    <>
      <Box display="flex">
        <ReportCreation blogId={blogId} />
      </Box>
      <Box display="flex">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box>
              <ReportsConfig blogId={blogId} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <ReportList blogId={blogId} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Reports
